import { getAuthToken } from '@/auth'
import { ExtractCharactersRequest, ExtractScenesRequest, FromDraftRequest, FromFrameRequest, FromScriptRequest, GenerateVideoRequest, GenerationProgress, OpenAIResponse, ExtractFastPromptRequest, Frame, ProjectSettings, GenerateCharacterRequest, UserPromptRequest, Scene, FrameWSObject } from '@/types'

const websocketDomain = import.meta.env.VITE_WEBSOCKET_DOMAIN

export type GenerationError = { error: string, code?: string }

export type FromFrameResponse = { predictedImage: { image: string, id: string, seed: number } } | { event: 'progress', progress: GenerationProgress }
export type GenerateCharacterResponse = FromFrameResponse
export type FromScriptResponse = { openAIPrompt: OpenAIResponse }
export type FromDraftResponse = { script: string[], title: string }
export type VideoGenerationResponse = { frame_id: string, video_url: string }

export type ExtractCharactersResponse = {
  type: string;
  data: { name: string; avatar: string, clothes: string[], style: ProjectSettings['style'] }[];
};

export type ExtractScenesResponse = {
  type: string;
  data: { id: string, location: string; description: string }[];
}

export type ExtractFastPromptResponse = {
  frames: Frame[];
};

export type UserPromptResponse = {
  type: string;
  data: {
    frames: FrameWSObject[];
    scenes: Scene[]
  };
};

type WebSocketMessage = FromDraftResponse | FromFrameResponse | FromScriptResponse | VideoGenerationResponse | GenerationError | ExtractCharactersResponse | ExtractScenesResponse | ExtractFastPromptResponse | ExtractCharactersResponse | false;

export type RequestUnion = FromFrameRequest | FromScriptRequest | FromDraftRequest | GenerateVideoRequest | ExtractCharactersRequest | ExtractScenesRequest | ExtractFastPromptRequest | GenerateCharacterRequest | UserPromptRequest

type ResponseType<T extends RequestUnion> =
  T extends { action: 'from_frame' | 'generate_character' } ? FromFrameResponse :
  T extends { action: 'from_script' } ? FromScriptResponse :
  T extends { action: 'from_draft' } ? FromDraftResponse :
  T extends { action: 'generate_video' } ? VideoGenerationResponse :
  T extends { action: 'extract_characters' } ? ExtractCharactersResponse :
  T extends { action: 'extract_scenes' } ? ExtractScenesResponse :
  T extends { action: 'fast_prompts' } ? ExtractFastPromptResponse :
  T extends { action: 'from_user_prompt' } ? UserPromptResponse :
  never;

function isError (message: WebSocketMessage): message is GenerationError {
  return message !== false && 'error' in message
}

export async function * establishSocketConnection<T extends RequestUnion> (requestBody: T): AsyncGenerator<GenerationError | ResponseType<T>, void, unknown> {
  const token = await getAuthToken()

  const url = new URL('/api/v2/storyboard', websocketDomain)

  const connection = new WebSocket(url)
  let connectionPromiseResolve: (value: WebSocketMessage) => void
  connection.onopen = () => {
    console.log('Connected')
    connection.send(JSON.stringify({ type: 'auth', token }))
    connection.send(JSON.stringify(requestBody))
  }
  connection.onerror = (error) => {
    console.log(`WebSocket error: ${JSON.stringify(error)}`)
    console.error(error)
    connectionPromiseResolve(false)
  }
  connection.onclose = () => {
    connectionPromiseResolve(false)
    // Handle closure if you wish. This could be a signal to stop the generator.
  }
  connection.onmessage = (e) => {
    if (e.data === 'ping') {
      connection.send('pong')
      return
    }
    const message = JSON.parse(e.data)
    const res = {
      data: message
    }
    connectionPromiseResolve(res.data)
  }
  while (true) {
    const message: WebSocketMessage = await new Promise((resolve) => {
      connectionPromiseResolve = resolve
    })
    if (message === false) {
      break
    } else if (isError(message)) {
      yield message
    } else {
      yield message as ResponseType<T>
    }
  }
}
