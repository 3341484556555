<template>
  <router-view />
  <v-dialog
    v-model="payUpModal"
    width="auto"
  >
    <PaymentCard @close="payUpModal = false" />
  </v-dialog>

  <v-dialog
    v-model="referralModal"
    width="auto"
  >
    <ReferralCard @close="referralModal = false" />
  </v-dialog>
  <v-dialog
    v-model="mobileModal"
    width="auto"
  >
    <v-card>
      <v-card-title>
        <div class="title">
          Mobile devices not supported
        </div>
      </v-card-title>
      <v-card-text>
        <div class="text">
          <p>
            You are currently using Katalist on mobile. For best experience, please switch to a desktop or laptop to
            continue.
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="primary"
          @click="mobileModal = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <activate-now />
</template>

<script setup lang="ts">
import { provide, watch, ref, onMounted } from 'vue'
import { useUserStore } from '@/store/user'
import { isUserLoggedIn } from '@/auth'
import { useRoute } from 'vue-router'

import PaymentCard from '@/components/PaymentCard.vue'
import ReferralCard from '@/components/ReferralCard.vue'
import ActivateNow from '@/components/ActivateNow.vue'
import tracking from '@/services/tracking'
import { startFullStory } from '@/services/fullstory'
const userStore = useUserStore()
const route = useRoute()
const mobileModal = ref(false)
const referralModal = ref(false)

onMounted(async () => {
  const loggedIn = await isUserLoggedIn()

  if (!loggedIn) {
    return
  }
  await userStore.setup()

  tracking.identify(userStore.user!, userStore.org!)

  startFullStory(userStore.user!.id, userStore.user!.email!)
})

watch(() => route.fullPath, async () => {
  const paymentRouts = ['/payment-done']
  const paymentRegexp = new RegExp(paymentRouts.join('|'), 'i')
  const paymentConditions = paymentRegexp.test(route.path) || !!route.query.trial
  if (!paymentConditions &&
  window.innerWidth < 768 && route.meta.requiresAuth) {
    mobileModal.value = true
  }
})

const sidebarOpen = ref(true)
const payUpModal = ref(false)
const videoMode = ref(false)
const activateNowModal = ref(false)

provide('sidebarOpen', sidebarOpen)
provide('payUpModal', payUpModal)
provide('videoMode', videoMode)
provide('referralModal', referralModal)
provide('activateNowModal', activateNowModal)
</script>

<style>
@font-face {
  font-family: 'ABC Marist Variable Unlicensed Trial';
  src: url("@/assets/fonts/ABCMarist-Medium-Trial.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCMarist SemiBold Trial';
  src: url("@/assets/fonts/ABCMarist-SemiBold-Trial.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fold Grotesque Pro Italic';
  src: url("@/assets/fonts/FoldGrotesquePro-Italic.ttf") format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Fold Grotesque Pro Medium';
  src: url("@/assets/fonts/FoldGrotesquePro-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fold Grotesque Pro';
  src: url("@/assets/fonts/FoldGrotesquePro-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url("@/assets/fonts/Poppins-Medium.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url("@/assets/fonts/Poppins-SemiBold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Mulish fonts */
@font-face {
  font-family: Mulish-Bold;
  src: url("@/assets/fonts/Mulish/Mulish-Bold.ttf")  format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Mulish-ExtraBold;
  src: url("@/assets/fonts/Mulish/Mulish-ExtraBold.ttf")  format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: Mulish-Regular;
  src: url("@/assets/fonts/Mulish/Mulish-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Mulish-SemiBold;
  src: url("@/assets/fonts/Mulish/Mulish-SemiBold.ttf") format('truetype');
  font-weight: 600;
}

/* IBMPlexSans fonts */
@font-face {
  font-family: IBMPlexSans;
  src: url("@/assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: IBMPlexSans;
  src: url("@/assets/fonts/IBMPlexSans/IBMPlexSans-Bold.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
}
</style>
