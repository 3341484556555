<template>
  <v-layout>
    <v-snackbar
      v-model="showSnackbar"
      color="error"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-layout>
  <div class="pricing-option">
    <template
      v-for="(option, index) in calculatedOptions"
      :key="index"
    >
      <div
        class="option-card"
        :class="{ 'highlighted': option.title === 'Pro' }"
      >
        <div
          v-if="option.discountText"
          :style="{ background: option.discountBackground }"
          class="option-discount"
        >
          <div class="discount-text">
            {{ option.discountText }}
          </div>
        </div>
        <div class="option-content">
          <div
            v-if="option.title === 'Pro'"
            class="bookmark"
          />
          <section class="option-info pa-5 w-100">
            <div
              v-if="option.label"
              class="option-label"
            >
              <div class="label-text">
                {{ option.label }}
              </div>
            </div>
            <div
              class="option-title"
              :style="{ fontFamily: option.fontFamily }"
            >
              {{ option.title }}
            </div>
            <div class="option-description">
              {{ option.description }}
            </div>
            <div
              class="option-price"
              :class="{ yearly: yearlyBillingPeriod }"
            >
              <div class="price-value">
                <span class="number">
                  {{ option.price }}
                </span>
                <span
                  v-if="option.pricePeriod"
                  class="price-period"
                >
                  {{ option.pricePeriod }}
                </span>
                <br>
              </div>
              <!-- <div
                v-if="!yearlyBillingPeriod"
                class="full-yearly-price"
              >
                <span
                  v-if="option.discountPrice"
                  class="number"
                >
                  {{ option.discountPrice }}
                </span>
                <span
                  v-if="option.discountPricePeriod"
                  class="price-period"
                >
                  {{ option.discountPricePeriod }}
                </span>
              </div> -->
              <div
                v-if="option.annualPrice"
                class="full-yearly-price"
              >
                <span class="number">
                  {{ option.annualPrice }}
                </span>
                <span class="price-period">
                  {{ option.annualPriceText }}
                </span>
              </div>
            </div>
            <v-btn
              class="option-button"
              :loading="loadingIndex == index ? 'primary' : false"
              :style="{ background: option.buttonBackground,
                        'border-color': option.buttonBorderColor,}"
              @click="option.buttonAction(index, option.title as 'Pro' | 'Essential' | 'Business')"
            >
              <div
                class="button-text"
                :style="{ color: option.buttonTextColor }"
              >
                {{ option.buttonText }}
              </div>
            </v-btn>

            <div
              class="cancel-text"
            >
              <sup v-if="option.cancelText">*</sup>
              {{ option.cancelText }}
            </div>
          </section>

          <div class="option-features pt-5 pa-5 w-100">
            <div
              v-if="option.generationCredits"
              class="d-flex align-center ga-2"
            >
              <img
                src="@/assets/checkmark-black.svg"
                alt="checkmark"
              >
              {{ option.generationCredits }}
            </div>
            <div class="d-flex align-center ga-2">
              <img
                src="@/assets/checkmark-black.svg"
                alt="checkmark"
              >
              All creative features
              <v-tooltip>
                <template #activator="{ props: slotProps }">
                  <img
                    v-bind="slotProps"
                    class="cursor-pointer"
                    src="@/assets/info-grey.svg"
                    alt="info"
                  >
                </template>
                <div style="max-width: 200px">
                  All creative features include: AI script assistant, storyboard generator, various artistic styles,
                  character library,
                  posing options, generative fill, and camera control.
                </div>
              </v-tooltip>
            </div>
            <div
              v-for="feature, findex in option.features"
              :key="findex"
              class="d-flex align-center ga-2"
            >
              <img
                src="@/assets/checkmark-black.svg"
                alt="checkmark"
              >
              {{ feature }}
            </div>
            <div
              v-for="feature, findex in option.newFeatures"
              :key="findex"
              class="d-flex align-center ga-2"
            >
              <img
                src="@/assets/checkmark-black.svg"
                alt="checkmark"
              >
              <b>{{ feature }}</b>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import tracking from '@/services/tracking'
import { onMounted, ref, computed } from 'vue'
import { useAppStore } from '@/store/app'
import { getAxios } from '@/services/axiosHelper'
import { useUserStore } from '@/store/user'

const appStore = useAppStore()
const userStore = useUserStore()

const props = defineProps({
  userEmail: {
    type: String,
    required: false
  },
  tier: {
    type: String,
    required: false
  },
  yearlyBillingPeriod: {
    type: Boolean,
    required: true
  },
  trialDisabled: {
    type: Boolean,
    required: false
  }
})

const loadingIndex = ref<number>(-1)

const showSnackbar = ref<boolean>(false)
const snackbarText = ref<string>('')

const nonpayingTiers = ['Free', 'Demo', 'Unpaid', 'AppSumo Tier 1', 'AppSumo Tier 2', 'AppSumo Tier 3']

const calculatedOptions = computed(() => {
  return options
    .filter((option) => {
      return [...nonpayingTiers, ...option.visibleForTiers].includes(props.tier!)
    })
    .filter((option) => {
      return props.tier! !== 'Unpaid' || shownToNewUsers.has(option.title)
    })
    .map((option) => {
      if (props.yearlyBillingPeriod) {
        return {
          ...option,
          ...option.yearly
        }
      } else {
        return option
      }
    })
    .map((option) => {
      if (props.trialDisabled) {
        return {
          ...option,
          buttonText: 'Upgrade'
        }
      } else {
        return option
      }
    })
})

const shownToNewUsers = new Set([
  'Essential',
  'Pro',
  'Unlimited',
  'Enterprise'
])

const options = [
  {
    visibleForTiers: [],
    title: 'Essential',
    description: 'For individuals looking to explore Katalist\'s storytelling platform capabilities.',
    price: '$19.00',
    pricePeriod: '/ month',
    buttonText: 'Try it free for 7 days',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonShadowColor: '#FF9ECF',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      '100 Video Credits',
      '20 Project Limit',
      '20 Panels per Project'
    ],
    fontFamily: 'Mulish-Bold',
    label: '',
    discountText: null,
    discountBackground: '#F6C86B',
    discountOpacity: '0',
    annualPrice: '',
    annualPriceText: '',
    generationCredits: '200 image credits / month',
    yearly: {
      price: '$29.00',
      annualPrice: '$348',
      annualPriceText: 'Billed Annually',
      label: null,
      discountOpacity: 0
    }
  },
  {
    visibleForTiers: ['Essential'],
    title: 'Pro',
    description: 'For professionals who need advanced features and more flexibility.',
    price: '$49.00',
    pricePeriod: '/ month',
    buttonText: 'Try it free for 7 days',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#000',
    buttonTextColor: '#FFF',
    buttonShadowColor: '#FF9ECF',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      '200 Video Credits',
      'Unlimited projects',
      '5 Custom Characters',
      '100+ panels per project'
    ],
    newFeatures: [
      'ZIP export',
      'PPT, Video Export'
    ],
    fontFamily: 'Mulish-Bold',
    label: 'Popular',
    discountText: null,
    discountBackground: '#FFC658',
    discountOpacity: '0',
    generationCredits: '1000 image credits / month',
    yearly: {
      price: '$39.00',
      annualPrice: '$468',
      annualPriceText: 'Billed Annually'
    }
  },
  {
    visibleForTiers: ['Essential', 'Pro'],
    title: 'Unlimited',
    description: 'For power users and small teams needing unlimited resources.',
    price: '$99.00',
    fullYearlyPrice: '$948',
    pricePeriod: '/ month for 2 users',
    buttonText: 'Try it free for 7 days',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonShadowColor: '#FF9ECF',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      'Unlimited Video Credits',
      'Unlimited projects',
      '10 Custom Characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video Export'
    ],
    newFeatures: [
      '2 users'
    ],
    discountText: null,
    discountBackground: '#FFC658',
    discountOpacity: '0',
    fontFamily: 'Mulish-Bold',
    generationCredits: 'Unlimited image generation',
    yearly: {
      price: '$79.00',
      annualPrice: '$948',
      annualPriceText: 'Billed Annually'
    }
  },
  {
    visibleForTiers: ['Essential', 'Individual', 'Pro'],
    title: 'Business',
    description: 'For teams agencies, studios, and brands that want to work together in real time.',
    price: '$499.00',
    pricePeriod: '/ month for 5 users',
    buttonText: 'Try it free for 7 days',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonShadowColor: '#FF9ECF',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    borderWidth: '2px',
    features: [
      '5 users',
      'Unlimited video credits',
      'Unlimited projects',
      '25 custom characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video export'
    ],
    newFeatures: [
      'Real-time collaboration'
    ],
    discountText: null,
    discountBackground: '#111',
    discountOpacity: '0',
    fontFamily: 'Mulish-Bold',
    generationCredits: 'Unlimited image generation',
    yearly: {
      annualPrice: '$4788',
      annualPriceText: 'Billed Annually',
      price: '$399.00'
    }
  },
  {
    visibleForTiers: ['Essential', 'Individual', 'Business', 'Pro'],
    title: 'Enterprise',
    description: 'For large organisations that work at scale.',
    price: 'Contact Us',
    pricePeriod: null,
    buttonText: 'Book Demo',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonShadowColor: '#FF9ECF',
    buttonAction: getInTouch,
    borderWidth: '2px',
    features: [
      'Unlimited Users',
      'Unlimited video credits',
      'Unlimited projects',
      'Unlimited custom characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video export',
      'Real-time collaboration'
    ],
    newFeatures: [
      'Enterprise Integrations',
      '24/7 Dedicated Support'
    ],
    generationCredits: 'Scaleable for large orgs',
    discountText: null,
    discountBackground: '#111',
    discountOpacity: '0',
    fontFamily: 'Mulish-Bold'
  }
]

onMounted(async () => {
  if (!appStore.projects) {
    await appStore.getProjects()
  }
})

async function startPaidPlan (index: number, tier: 'Free' | 'Pro' | 'Business' | 'Essential' | 'Unlimited') {
  if (loadingIndex.value !== -1) {
    return
  }

  const trial = userStore.org!.tier !== 'Free' && userStore.org?.subscription_status === 'registered'

  let mappedTier
  if (tier === 'Unlimited') {
    mappedTier = 'Individual'
  } else {
    mappedTier = tier
  }

  loadingIndex.value = index
  tracking.track('Plan Selected', { 'Plan type': tier, trial })

  const axios = await getAxios()

  const checkoutUrl = '/api/checkout'

  try {
    const response = await axios.get(checkoutUrl, {
      params: {
        customerEmail: props.userEmail!,
        tier: mappedTier,
        trial,
        seats: 5,
        billingPeriod: props.yearlyBillingPeriod ? 'year' : 'month',
        applyIndividualDiscount: false
      }
    })
    window.location.assign(response.data.redirect_url)
  } catch (e: any) {
    loadingIndex.value = -1
    showSnackbar.value = true
    if (e.response?.data?.detail) {
      snackbarText.value = e.response.data.detail
    } else {
      snackbarText.value = 'An error occurred. Please try again later.'
    }
  }
}

function getInTouch () {
  tracking.track('Plan Selected', { 'Plan type': 'Studio', CTA: 'Get in touch' })
  window.location.assign('mailto:sales@katalist.ai&subject=Studio')
}
</script>

<style scoped lang="scss">
@import "@/styles/settings";

$light-pink: #FFF4FA;
$dark-pink: #F2CEE2;

.pricing-option {
  justify-content: center;
  align-items: end;
  gap: 56px;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 30px;
  padding-bottom: 30px;

  @include xl {
    flex-direction: row;
  }
}

.option-card {
  width: 100%;
  max-width: 360px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.period-tabs {
  color: #111 !important;
  margin-bottom: 1rem;
  /* stylelint-disable-next-line selector-class-pattern */
  :deep(.v-slide-group-item--active) {
    color: #fFF !important;
    background-color: #111 !important;

    .yearly-discount {
      color: #FF9ECF;
      padding-left: 4px;
    }
  }

}

.option-content {
  width: 100%;
  max-width: 320px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.option-title {
  margin-top: 15px;
  margin-bottom: 6px;
  color: #111;
  font: normal normal bold 20px/28px Mulish-Bold, sans-serif;
  letter-spacing: 0.17px;
  word-wrap: break-word;
}

.alter-text {
  height: 35px;
  font-size: 14px;
  color: #111;
  text-align: center;
  width: 100%;
}

.alter-text-decoration {
  text-decoration: underline;
  cursor: pointer;

}

.option-description {
  align-self: stretch;
  margin-bottom: 10px;
  color: #111;
  font: normal normal normal 12px/18px Mulish-Regular, sans-serif;
  letter-spacing: 0.1px;
  word-wrap: break-word;
}

.option-price {
  margin-bottom: 31px;
  align-self: stretch;
  height: 65px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.price-value {
  color: #111;
  word-wrap: break-word;

  .number{
    font: normal normal bold 30px/42px Mulish-Bold, sans-serif;
    letter-spacing: 0.25px;
  }

  .price-period {
    margin-left: 4px;
    font: normal normal 600 12px/18px Mulish-SemiBold, sans-serif;
    letter-spacing: 0.1px;
    word-wrap: unset;
    overflow: visible;
    text-wrap: nowrap;
  }

}

.price-value-crossed {
  color: #111;
  font-size: 20px;
  font-family: 'Fold Grotesque Pro', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  text-decoration: line-through;

}

.option-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding:11px 35px;
  background: #FFF 0% 0% no-repeat padding-box;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
}

.button-text {
  text-transform: capitalize;
  text-align: center;
  font: normal normal bold 12px/18px Mulish-Bold, sans-serif;
  letter-spacing: 0.1px;
  color: #000;
  word-wrap: break-word;
}

.option-features {
  background-color: #FFF;
  color: #111;
  align-self: stretch;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 6px;
  height: 370px;
  display: flex;
  text-wrap: nowrap;
  border-radius: 0 0 4px 4px;
  font: normal normal normal 12px/18px Mulish-Regular, sans-serif;
  letter-spacing: 0.1px;
}

.option-label {
  padding:10px 7px;
  background: #FFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 0 #000;
  border: 1px solid #000;
  border-radius: 5px;
  width:80px;
}

.label-text {
  text-align: center;
  font: normal normal bold 12px/16px Mulish-Bold, sans-serif;
  letter-spacing: 0.1px;
  color: #000;
  text-transform: uppercase;
  word-wrap: break-word;
}

.option-discount {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  padding: 7px 14px;
  border-radius: 15px;
}

.discount-text {
  text-align: center;
  color: #111;
  font-size: 12px;
  font-family: 'Fold Grotesque Pro Medium', sans-serif;
  font-weight: 600;
  line-height: 19.20px;
  word-wrap: break-word;
}

.seats {
  padding-bottom: 2px;
  height: 30px;
  color: #111;

}

.generation-credits {
  color: #111;
  font-size: 16px;
  font-family: 'Fold Grotesque Pro', sans-serif;
  font-weight: 1000;
  line-height: 19.20px;
  word-wrap: break-word;
}

.option-info{
  background-color: $light-pink;
  border-radius: 4px 4px 0 0;
  height: 293px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlighted {
  .option-info{
    background-color: $dark-pink;
    height: auto;
  }

  .bookmark{
    background-image: url("@/assets/bookmark-white.svg");
    width: 50px;
    height: 50px;
    position: absolute;
    top: -15px;
    right: 10px;
  }
}

.full-yearly-price {
  color: #111;
  height: 20px;
  word-wrap: break-word;
  margin-top: 6px;

  .number{
    font: normal normal bold 18px/24px Mulish-Bold, sans-serif;
    letter-spacing: 0.15px;
    margin-right: 4px;
  }

  .price-period{
    font: normal normal 600 12px/18px Mulish-SemiBold, sans-serif;
    letter-spacing: 0.1px;

  }
}

.cancel-text{
  text-align: right;
  font: normal normal bold 10px/16px Mulish-Bold, sans-serif;
  letter-spacing: 0.08px;
  color: #000;
  opacity: 0.5;
  height: 16px;
}
</style>
